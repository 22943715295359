<template>
  <div class="file">
    <div class="add">
      <div class="input">
        <input-dataset-box
          v-model="fileInputValue.file"
          class="input-file"
          :errors="errors"
          @on-file-input="fileInput"
        />
        <div class="input-first">
          <div class="input-first-title">
            <div>
              <texts
                :text="$t('datasetList.popup.addDataset.datasetName')"
                size="small"
                color="gray"
              />
            </div>
            <button
              class="input-first-info"
              @click="$emit('go-manual-dataset-type')"
            >
              <text-with-icon
                :text="$t('datasetSetting.info')"
                iconName="info"
                size="min"
                color="green"
              />
            </button>
          </div>
          <input-box-validation
            class="input-name"
            :accept="validate.accept"
            :error="validate.error"
            :placeholder="
              $t('datasetList.popup.addDataset.datasetNamePlaceholder')
            "
            :value.sync="fileInputValue.name"
            popupView
            isNameValidation
            :nameValidationSuggests="datasetFormValidate.suggests"
            @input="createDataset = $event"
          />
        </div>
        <input-box
          v-model="fileInputValue.description"
          class="input-desc"
          isTextArea
          isGray
          :title="$t('datasetList.popup.addDataset.description')"
          :placeholder="
            $t('datasetList.popup.addDataset.descriptionPlaceholder')
          "
        />
      </div>
      <button-large
        class="button"
        :text="$t('datasetSetting.file.uploadDataset')"
        :isDisabled="submitDisabled || validate.error || fileError != null"
        @click="$emit('upload-file', fileInputValue)"
      />
    </div>
    <div class="table">
      <dataset-setting-table :table="preview" :columns="columns" />
    </div>
  </div>
</template>

<script>
import buttonLarge from '@/components/atoms/button-large'
import datasetSettingTable from './dataset-setting-table'
import inputBox from '@/components/molecules/input-box'
import inputBoxValidation from '../../molecules/input-box-validation.vue'
import inputDatasetBox from '@/components/molecules/input-dataset-box'
import textWithIcon from '@/components/molecules/text-with-icon'

import { getSamples } from '@/lib/csvPreview'
import { mapGetters } from 'vuex'

export default {
  components: {
    buttonLarge,
    datasetSettingTable,
    inputBox,
    inputBoxValidation,
    inputDatasetBox,
    textWithIcon
  },
  data() {
    return {
      /** 新規追加するデータセットのv-model */
      fileInputValue: {
        name: '',
        description: '',
        file: null
      },
      /** submitボタンをdisabledにするかどうか */
      preview: null,
      columns: [],

      validate: {
        accept: null,
        error: null
      },
      /** fileInputValueのnameをlengthをつけてboolしてもうまくいかなかったので
       * 別の判定するデータを作りました */
      createDatasetNameValue: false,
      fileError: null
    }
  },
  props: {
    datasetFormValidate: Object,
    /** ファイルをアップロードした時にエラーが発生したらエラーメッセージをここに格納する */
    uploadLearningDataErrors: Object,
    resetInputValue: Boolean
  },
  computed: {
    ...mapGetters('settings', ['rowsPerPage']),

    createDataset: {
      get() {
        if (this.fileInputValue.file) {
          return this.fileInputValue.file.name
        }
        return ''
      },
      set(val) {
        this.createDatasetNameValue = val.length
        if (!val.length) {
          this.fileInputValue.name = ''
        } else {
          this.fileInputValue.name = val
        }
      }
    },
    submitDisabled() {
      return !this.createDatasetNameValue || !this.fileInputValue.file
    },
    errors() {
      const res = []
      if (this.fileError != null) {
        return [this.fileError]
      }
      if (this.uploadLearningDataErrors.message) {
        for (const error of this.uploadLearningDataErrors.message) {
          if (error === 'aws awsbatch failed error') {
            res.push('AWSBATCH_FAILED_ERROR')
          } else {
            res.push(error)
          }
        }
      }
      return res
    }
  },
  methods: {
    /** 本来はファイル情報を読み込んで
     *  ファイルのデータセットを表示する。
     *  ただデータセット管理側で機能実装がまだ？なので
     *  そちらが完了次第実装。間に合わないとかできないなら削除する
     */
    fileInput(e) {
      this.$emit('on-file-input', e)
      this.createDatasetNameValue = e.name.length
      this.fileInputValue.name = e.name.substr(0, e.name.lastIndexOf('.'))
      this.fileInputValue.file = e
      this.makePreview()
      this.$emit('on-file-input', e)
    },
    async makePreview() {
      this.preview = null
      this.columns = null
      this.fileError = null
      const selectedFile = this.fileInputValue?.file
      if (!selectedFile) return
      if (selectedFile.name.search(/\.csv$/i) === -1) return
      const {
        columns,
        entries,
        error = null
      } = await getSamples(selectedFile, this.rowsPerPage)
      if (error != null) {
        this.fileError = error
        return
      }

      this.preview = []
      for (const line of entries) {
        this.preview.push(line)
      }
      this.columns = columns
    },
    clearForm({ emit = true } = {}) {
      this.fileInputValue.name = ''
      this.fileInputValue.description = ''
      this.fileInputValue.file = null
    }
  },
  watch: {
    datasetFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    },

    // データセットの名前などを入力したか確認
    fileInputValue: {
      deep: true,
      handler: function (val) {
        if (val.name) {
          this.$emit('input-create-form', {
            type: 'dataset',
            form: val
          })
        }
      }
    },
    resetInputValue(newVal) {
      if (newVal) {
        this.clearForm()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.file {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr adjustVW(966);
  grid-template-rows: 100%;
  grid-column-gap: $space-medium;
  height: 100%;
}
.add {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.button {
  flex-shrink: 0;
}
.table {
  overflow: hidden;
}
.input {
  display: grid;
  flex-shrink: 1;
  grid-template-rows: minmax(auto, adjustVH(280)) min-content min-content;
  grid-row-gap: $space-small;
  height: calc(100% - #{$space-medium});
  margin: 0 0 $space-medium;
  &-first {
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $space-base;
    }
  }
}
</style>
